import { deepmerge } from '@mui/utils';
import type { Theme, ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { DEFAULTCOLOR_TEXT_DISABLED, augmentColor } from '../default/palette';
import type { Device } from '../default';
import { generateDefaultThemeOptions } from '../default';
import { fonts } from './fonts';

const COLOR_PRIMARY_MAIN = '#5b1f67';
const COLOR_SECONDARY_MAIN = '#9d7692';
const DEFAULTCOLOR_TEXT_DARK = '#000000';
const DEFAULTCOLOR_TEXT_LIGHT = '#ffffff';
const DEFAULTCOLOR_TEXT_TRANSPARENT_DARK = 'rgba(0, 0, 0, 0.65)';
const DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT = 'rgba(255, 255, 255, 0.65)';
const DEFAULTCOLOR_COMMON_LIGHT = '#f4f3f2';
const FOOTER_CONTRAST_TEXT = '#542364';
const FOOTER_MAIN = '#F3EEF0';

/** Define theme object.
 * See here for more info: https://mui.com/customization/theming/ */
const themeOptions: ThemeOptions = {
  // https://mui.com/customization/palette/
  palette: {
    primary: {
      main: COLOR_PRIMARY_MAIN,
      textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
      contrastText: DEFAULTCOLOR_TEXT_LIGHT,
    },
    secondary: {
      main: COLOR_SECONDARY_MAIN,
    },
    grey: {
      main: '#b9b9b9',
      light: DEFAULTCOLOR_COMMON_LIGHT,
      dark: '#444444',
    },
    text: {
      disabled: DEFAULTCOLOR_TEXT_DISABLED,
      primary: DEFAULTCOLOR_TEXT_DARK,
      secondary: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      dark: DEFAULTCOLOR_TEXT_DARK,
      darkTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_DARK,
      light: DEFAULTCOLOR_TEXT_LIGHT,
      lightTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
    },
    sale: augmentColor({
      color: {
        main: '#bc096d',
        contrastText: DEFAULTCOLOR_TEXT_LIGHT,
        textTransparent: DEFAULTCOLOR_TEXT_TRANSPARENT_LIGHT,
      },
    }),
    footer: {
      main: FOOTER_MAIN,
      contrastText: FOOTER_CONTRAST_TEXT,
    },
  },
  typography: {
    fontFamily: fonts.primary.style.fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightBold: 700,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: '',
    },
    MuiButton: {
      styleOverrides: {
        root: ({
          theme: {
            unstable_sx: sx,
            palette: { grey },
          },
        }) =>
          sx({
            ':disabled': { bgcolor: grey.main, color: grey.dark },
          }),
      },
    },
  },
};

/** Merge with default theme options and create a theme instance
 * See here for more info: https://mui.com/customization/theming/#api */
const themes: { [type in Device]: Theme | undefined } = {
  mobile: undefined,
  tablet: undefined,
  desktop: undefined,
};

const theme = (device: Device = 'desktop') => {
  if (!themes[device]) {
    themes[device] = createTheme(deepmerge(generateDefaultThemeOptions(device), themeOptions));
  }

  return themes[device];
};

// eslint-disable-next-line import/no-default-export
export default theme;
