import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgBurgerText32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" id="burger-text-32_svg__Laag_1" data-name="Laag 1" viewBox="0 0 100 100" sx={{
    ...sx
  }} {...other}><defs><style>{".burger-text-32_svg__cls-1{fill:#5b1f67;stroke-width:0}.burger-text-32_svg__cls-2{fill:none;stroke:#5b1f66}.burger-text-32_svg__cls-3{isolation:isolate}"}</style></defs><path id="burger-text-32_svg__Rectangle_53" d="M5 6h90c2.76 0 5 2.24 5 5s-2.24 5-5 5H5c-2.76 0-5-2.24-5-5s2.24-5 5-5" className="burger-text-32_svg__cls-1" data-name="Rectangle 53" /><path id="burger-text-32_svg__Rectangle_54" d="M5 31h90c2.76 0 5 2.24 5 5s-2.24 5-5 5H5c-2.76 0-5-2.24-5-5s2.24-5 5-5" className="burger-text-32_svg__cls-1" data-name="Rectangle 54" /><path id="burger-text-32_svg__Rectangle_55" d="M5 56h90c2.76 0 5 2.24 5 5s-2.24 5-5 5H5c-2.76 0-5-2.24-5-5s2.24-5 5-5" className="burger-text-32_svg__cls-1" data-name="Rectangle 55" /><g id="burger-text-32_svg__MENU" className="burger-text-32_svg__cls-3"><g className="burger-text-32_svg__cls-3"><path d="M4.13 78h2.73l7.53 10.86L21.89 78h2.73v21h-2.4V81.78l-7.86 11.04-7.83-11.07V99h-2.4zM32.63 78h14.4v2.25H35.15v6.99h10.71v2.13H35.15v7.38h12.06V99H32.63zM54.68 78h2.55l11.73 17.1V78h2.43v21h-2.61L57.11 81.93V99h-2.43zM79.34 91.23V78h2.52v13.23c0 3.87 2.58 5.94 5.79 5.94s5.79-2.07 5.79-5.94V78h2.52v13.23c0 5.4-3.63 8.13-8.31 8.13s-8.31-2.73-8.31-8.13" className="burger-text-32_svg__cls-1" /></g><g className="burger-text-32_svg__cls-3"><path d="M4.13 78h2.73l7.53 10.86L21.89 78h2.73v21h-2.4V81.78l-7.86 11.04-7.83-11.07V99h-2.4zM32.63 78h14.4v2.25H35.15v6.99h10.71v2.13H35.15v7.38h12.06V99H32.63zM54.68 78h2.55l11.73 17.1V78h2.43v21h-2.61L57.11 81.93V99h-2.43zM79.34 91.23V78h2.52v13.23c0 3.87 2.58 5.94 5.79 5.94s5.79-2.07 5.79-5.94V78h2.52v13.23c0 5.4-3.63 8.13-8.31 8.13s-8.31-2.73-8.31-8.13Z" className="burger-text-32_svg__cls-2" /></g></g></SvgIcon>;
};
const Memo = memo(SvgBurgerText32);
export default Memo;