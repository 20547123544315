import localFont from 'next/font/local';

const primary = localFont({
  src: [
    { path: './fonts/roboto-thin.woff', weight: '300', style: 'normal' },
    { path: './fonts/roboto-regular.woff', weight: '400', style: 'normal' },
    { path: './fonts/roboto-bold.woff', weight: '700', style: 'normal' },
  ],
  variable: '--font-primary',
});

export const fonts = {
  primary,
};
