import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSuccessCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="success-circle-32_svg__a" d="M16 4c6.628 0 12 5.373 12 12A12 12 0 1 1 16 4m0 1.253C10.067 5.253 5.255 10.065 5.255 16s4.812 10.746 10.747 10.746S26.747 21.935 26.747 16c0-5.935-4.811-10.747-10.746-10.747m3.574 7.644L20.78 14.1l-.603.602c-1.345 1.348-2.821 2.934-4.227 4.357l-1.01 1.016-3.009-2.477-.648-.539 1.085-1.32.654.54 1.811 1.49c1.35-1.376 2.784-2.91 4.139-4.268z" /></defs><g fill="none" fillRule="evenodd"><mask id="success-circle-32_svg__b" fill="#fff"><use xlinkHref="#success-circle-32_svg__a" /></mask><g fill="#7DAE2E" mask="url(#success-circle-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgSuccessCircle32);
export default Memo;