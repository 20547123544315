import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgAppIcon = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" data-name="Ebene 1" viewBox="0 0 213.44 213.44" sx={{
    ...sx
  }} {...other}><circle cx={106.72} cy={106.72} r={106.72} style={{
      strokeWidth: 0,
      fill: "#5b1f67"
    }} /><path d="m153.59 142.97-5.22-1.5c-2.2-.54-3.11 1.66-3.83 3.45-1.68 4.18-4.49 11.18-18.72 11.18h-18.18c-7.59 0-10.95-.63-10.95-15.64v-84.6c0-6.23 6.38-7.83 9.3-8.26l.43-.11c1.42-.34 3.56-.86 4.61-3.47l1.24-2.96c.72-1.68.36-2.81-.08-3.47-1.07-1.62-3.37-1.62-4.13-1.62H66.72c-2.12 0-3.73.64-4.99 2.22l-1.62 3.28c-.69 1.24-.78 2.51-.25 3.57.42.83 1.4 1.89 3.67 2.2 10.43 1.91 11.87 2.96 11.87 8.6v93.72c0 6.24-6.38 7.83-9.3 8.26l-.43.11c-1.41.34-3.55.86-4.61 3.47l-1.23 2.96c-.72 1.68-.35 2.81.08 3.47 1.07 1.62 3.37 1.62 4.13 1.62h80.83c6.88 0 11.16-2.41 11.16-6.3v-16.92c0-1.61-1.01-2.93-2.44-3.26" style={{
      fill: "#fff",
      strokeWidth: 0
    }} /></SvgIcon>;
};
const Memo = memo(SvgAppIcon);
export default Memo;