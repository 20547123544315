import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgSearch32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M13.499 4a9.499 9.499 0 0 1 9.498 9.499c0 2.203-.75 4.23-2.009 5.842l6.74 6.74L25.81 28l-6.778-6.78a9.455 9.455 0 0 1-5.532 1.777 9.499 9.499 0 0 1 0-18.997m0 1.248a8.196 8.196 0 0 0-5.834 2.417 8.196 8.196 0 0 0-2.417 5.834c0 2.203.859 4.275 2.417 5.833a8.196 8.196 0 0 0 5.834 2.417 8.196 8.196 0 0 0 5.833-2.417A8.196 8.196 0 0 0 21.75 13.5a8.196 8.196 0 0 0-2.417-5.834A8.196 8.196 0 0 0 13.5 5.248" /></SvgIcon>;
};
const Memo = memo(SvgSearch32);
export default Memo;