import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgInformationCircle32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="information-circle-32_svg__a" d="M16 4a12 12 0 1 0 12 12c0-6.627-5.372-12-12-12m0 22.746c-5.934 0-10.746-4.811-10.746-10.746 0-5.935 4.812-10.747 10.747-10.747S26.747 10.065 26.747 16s-4.811 10.746-10.746 10.746m2.507-6.182a.626.626 0 0 1-.626.627h-3.134a.626.626 0 0 1 0-1.253h.94v-4.176h-.194a.626.626 0 1 1 0-1.253h.82c.347 0 .627.28.627.626v4.803h.94a.626.626 0 0 1 .627.627zm-3.51-8.127a1.002 1.002 0 1 1 2.006 0 1.002 1.002 0 0 1-2.005 0" /></defs><g fill="none" fillRule="evenodd"><mask id="information-circle-32_svg__b" fill="#fff"><use xlinkHref="#information-circle-32_svg__a" /></mask><use xlinkHref="#information-circle-32_svg__a" fill="#5B1F67" fillRule="nonzero" /><g fill="#8B969C" mask="url(#information-circle-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgInformationCircle32);
export default Memo;