import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgNewsletter32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="newsletter-32_svg__a" d="M2.34 0A2.38 2.38 0 0 0 0 2.406V14.56a2.389 2.389 0 0 0 2.34 2.415h19.32A2.389 2.389 0 0 0 24 14.561V2.406A2.38 2.38 0 0 0 21.66 0zm0 1.17h19.32c.167.004.333.045.484.12l-9.423 8.945a.98.98 0 0 1-1.425 0L1.857 1.291a1.15 1.15 0 0 1 .485-.119zM1.189 2.268l6.666 6.32-6.558 6.549a1.323 1.323 0 0 1-.128-.576V2.406c0-.048.014-.092.018-.138zm21.623 0c.008.045.014.091.018.137v12.156c0 .196-.044.39-.128.567l-6.54-6.549 6.65-6.31zm-7.5 7.123 6.402 6.412H2.296L8.707 9.4l1.784 1.683a2.21 2.21 0 0 0 3.037 0z" /></defs><g fill="none" fillRule="evenodd" transform="translate(4 8)"><mask id="newsletter-32_svg__b" fill="#fff"><use xlinkHref="#newsletter-32_svg__a" /></mask><use xlinkHref="#newsletter-32_svg__a" fill="#5B1F67" fillRule="nonzero" /><g fill="#000" mask="url(#newsletter-32_svg__b)"><path d="M-4-8h32v32H-4z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgNewsletter32);
export default Memo;