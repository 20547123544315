import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgTruck32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="truck-32_svg__a" d="M18.505 6a2.818 2.818 0 0 1 2.814 2.815v.34h2.498c.932 0 1.804.462 2.327 1.233l2 2.938-.018.012 1.276 1.63c.386.495.597 1.106.598 1.735v4.97c0 .32-.26.579-.58.579h-2.81a3.243 3.243 0 0 1-6.414 0H10.57a3.243 3.243 0 0 1-6.414 0H2.58a.58.58 0 0 1 0-1.16h1.61a3.243 3.243 0 0 1 6.34 0h9.702a3.243 3.243 0 0 1 6.342 0h2.266v-4.39c0-.37-.124-.728-.351-1.02l-1.112-1.42H20.74a.58.58 0 0 1-.58-.58V8.815a1.657 1.657 0 0 0-1.654-1.655H6.602a.58.58 0 1 1 0-1.159zM7.36 19.685a2.084 2.084 0 1 0 0 4.167 2.084 2.084 0 0 0 0-4.167m16.044 0a2.084 2.084 0 1 0 0 4.167 2.084 2.084 0 0 0 0-4.167M11.599 14.22a.58.58 0 1 1 0 1.16H3.61a.58.58 0 1 1 0-1.16zm12.22-3.904H21.32v2.787h5.27l-1.402-2.062a1.653 1.653 0 0 0-1.368-.725m-10.18-.468a.58.58 0 1 1-.001 1.159H5.089a.58.58 0 0 1 0-1.16z" /></defs><g fill="none" fillRule="evenodd"><mask id="truck-32_svg__b" fill="#fff"><use xlinkHref="#truck-32_svg__a" /></mask><g fill="#000" mask="url(#truck-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgTruck32);
export default Memo;