import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgWishlist32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><defs><path id="wishlist-32_svg__a" d="m15.998 25.227-.247-.157C15.347 24.8 6 18.8 6 13.07c0-2.652 1.123-4.674 3.1-5.573 2.046-.921 4.653-.404 6.877 1.37 1.956-1.774 4.427-2.336 6.585-1.46C24.719 8.283 26 10.396 26 13.07c0 5.73-9.348 11.73-9.75 11.977zm-4.72-17.238a4.326 4.326 0 0 0-1.775.36c-1.617.74-2.561 2.45-2.561 4.719 0 4.718 7.55 10.022 9.058 11.01 1.483-1.01 9.057-6.291 9.057-11.01 0-2.27-1.056-4.067-2.854-4.787-1.91-.785-4.159-.18-5.865 1.55l-.315.315-.337-.292a6.903 6.903 0 0 0-4.407-1.863z" /></defs><g fill="none" fillRule="evenodd"><mask id="wishlist-32_svg__b" fill="#fff"><use xlinkHref="#wishlist-32_svg__a" /></mask><use xlinkHref="#wishlist-32_svg__a" fill="#5B1F67" fillRule="nonzero" /><g fill="#000" mask="url(#wishlist-32_svg__b)"><path d="M0 0h32v32H0z" /></g></g></SvgIcon>;
};
const Memo = memo(SvgWishlist32);
export default Memo;